import React from 'react'
import { Helmet } from 'react-helmet'
import { ClientSummary } from 'views/layout/Summary/ClientSummary'
import { Client, Project } from 'content/contentTypes'
import { List } from 'views/components'
import styles from './Client.module.scss'

interface Props {
  client: Client
}

export class ClientPage extends React.Component<Props, any> {
  componentDidMount() {
    const { client } = this.props
    window.analytics.identify({ traits: { company: client.company } })
  }

  render() {
    const { client } = this.props

    return (
      <div className="container">
        <Helmet>
          <title>A+D Client Portal - {client.name}</title>
          <meta name="robots" content="noindex" />
        </Helmet>
        <ClientSummary client={client} />
      </div>
    )
  }
}
