import logo from 'assets/images/logos/harbor.svg'

export const Harbor = {
  logo,
  name: 'Harbor',
  company: 'Harbor',
  projects: [
    {
      key: 'b18247',
      title: 'Harbor Marketing',
      startDate: 'Q3 2018',
      progress: {
        percentage: 0
      },
      deliverables: [
        {
          title: 'Website Update - April 9',
          progress: {
            percentage: 30,
          },
          open: [
            {
              title: 'Final Approval',
              done: false
            }
          ],
          files: [
            {
              title: 'Harbor Home Pages v2',
              url: 'https://docs.google.com/document/d/1BQyTMMAzfCfHf1wYJNKYHJK9CemMrdhuuF6kYteFR90/edit?usp=sharing',
              type: 'doc',
              date: '04/26/2019'
            },
            {
              title: 'Website Changes 4.9.19',
              url: 'https://docs.google.com/presentation/d/1qZ4PyqNBJGu90kAeuLdv_a8pZqnq6WPWCyVFsrtt1Y8/edit#slide=id.g57bf5caf9e_0_3',
              type: 'presentation',
              date: '04/09/2019'
            },
            {
              title: 'Harbor.com [Staging]',
              url: 'https://hdc-redesign.firebaseapp.com/',
              type: 'web'
            },
            {
              title: 'Harbor Archives',
              description: 'screenshot archives',
              url: 'https://drive.google.com/drive/folders/1K4pDY6HI8RP2TtwOAXezhNJI6rKeiCxU?usp=sharing',
              type: 'folder'
            }
          ]
        },
        {
          title: 'Harbor Blog',
          progress: {
            percentage: 80,
          },
          open: [
            {
              title: 'Add blog content',
              done: false
            },
            {
              title: 'GDPR Banner',
              done: false
            },
            {
              title: 'Analytics Tracking Plan',
              done: false
            },
            {
              title: 'Stakeholder Review',
              done: false
            }
          ],
          files: [
            {
              title: 'Harbor Blog [Staging]',
              url: 'http://staging-zufivogi.kinsta.cloud',
              type: 'web'
            },
            {
              title: 'Harbor Blog - Admin [Staging]',
              url: 'http://staging-zufivogi.kinsta.cloud/wp-admin/',
              type: 'web'
            },
            {
              title: 'Harbor_blog_r2.sketch',
              url: 'https://www.dropbox.com/s/l15k6dtu3zo0uz2/Harbor_blog_r2.sketch?dl=0',
              type: 'web'
            },
            {
              title: 'Design Files',
              url: 'https://www.dropbox.com/sh/jyxpj2l2sol6ga7/AAApELpRTmwrAqUDhSHqhiPQa?dl=0',
              type: 'folder'
            }
          ]
        }
      ]
    },
  ]


}