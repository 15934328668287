import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Summary } from 'views/layout/Summary/Summary'
import { DeliverableModule } from './Deliverable'
import { Client, Project } from 'content/contentTypes'
import styles from './Project.module.scss'

interface Props {
  client: Client
  project: Project
}

export class ProjectPage extends React.Component<Props, any> {

  componentDidMount() {
    const { client } = this.props
    window.analytics.identify({ traits: { company: client.company }})
  }

  backOnClick = () => {
    window.analytics.track('Back CTA Clicked', {
      label: 'Back',
      category: 'Project Page'
    })
  }

  render() {
    const { client, project } = this.props

    return (
      <div className="container">
        <Helmet>
          <title>{project.title} - {client.name} - A+D Client Portal</title>
          <meta name="robots" content="noindex" />
        </Helmet>
        {client.projects.length > 1 && client.to &&
          <Link to={client.to} className={styles.back} onClick={this.backOnClick}>
            <span className="icon is-small"><i className="material-icons">arrow_back</i></span> Back
          </Link>}
        <Summary client={client} project={project} />
        {project.deliverables && project.deliverables.map((deliverable, i) => (
          <DeliverableModule key={i} className={styles.deliverable} analytics={{ position: i + 1 }} deliverable={deliverable} />
        ))}
      </div>
    )
  }
}
