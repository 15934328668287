import classnames from 'classnames'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { ListItem as ListItemType } from 'content/contentTypes'
import { List } from './List'
import styles from './ListItem.module.scss'

import sheet from 'assets/images/spreadsheet.png'
import presentation from 'assets/images/presentation.png'
import invision from 'assets/images/invision.png'
import doc from 'assets/images/document.png'
import zeplin from 'assets/images/zeplin.svg'
import dropbox from 'assets/images/dropbox.svg'

const iconImages = {
  sheet,
  presentation,
  invision,
  doc,
  zeplin,
  dropbox
}
const iconFonts = {
  web: 'web_asset',
  folder: 'folder_open'
}

interface Props extends ListItemType {
  className?: string
  analytics: AnalyticsProps
  showProgress: boolean
}

interface State {
  done: boolean
}

export class ListItem extends React.Component<Props, any> {
  private linkRef: React.RefObject<HTMLAnchorElement>

  constructor(props: Props) {
    super(props)
    this.linkRef = React.createRef()
    this.state = {
      done: props.done
    }
  }

  handleCheckboxClick = () => {
    window.analytics.track('Checkbox Clicked', {
      category: 'Project Checklist',
      label: this.props.title
    })
  }

  componentDidMount() {
    const { analytics } = this.props
    window.analytics.trackLink(this.linkRef.current, analytics.action ? analytics.action : 'File Clicked', {
      label: this.props.title,
      ...analytics
    })
  }

  renderTypeIcon() {
    const { type } = this.props
    let icon
    if (!type) {
      return
    }
    if (Object.keys(iconFonts).includes(type)) {
      icon = <i className="material-icons">{iconFonts[type]}</i>
    } else {
      icon = <img src={iconImages[type]} />
    }
    return <span className={styles.icon}>{icon}</span>
  }

  renderName() {
    const { date, description, startDate, title, type } = this.props
    return (
      <>
        {type && this.renderTypeIcon()}
        <span className={styles.listItemName}>
          {title}
          {description && <span className={styles.description}>{description}</span>}
        </span>
        {date && <span className={styles.date}>{date}</span>}
        {startDate && <span className={styles.date}>{startDate}</span>}
      </>
    )
  }

  trackOnClick = () => {
    const { analytics } = this.props
    window.analytics.track(analytics.action ? analytics.action : 'File Clicked', {
      label: this.props.title,
      ...analytics
    })
  }

  render() {
    const { to, url, progress, open } = this.props
    return (
      <li className={classnames(styles.listItem, this.props.className)}>
        {progress && progress.percentage < 100 &&
          <progress
            className={classnames('progress is-primary', styles.progress)}
            value={progress.percentage}
            max="100"
          >
            {progress.percentage}%
          </progress>
        }
        {url && <a
          href={url}
          ref={this.linkRef}
          target="_blank"
          className={styles.listItemContent}
        >
          {this.renderName()}
        </a>}
        {to &&
          <Link
            className={styles.listItemContent}
            to={to}
            onClick={this.trackOnClick}
          >
              {this.renderName()}
          </Link>
        }
        {!url && !to &&
          <label
            className={classnames(styles.listItemContent, styles.checkListItem, 'checkbox')}
          >
            <input type="checkbox" onClick={this.handleCheckboxClick} checked={this.props.done} readOnly />
            {this.renderName()}
          </label>
        }
        {open &&
          <List items={open} />
        }
        </li>
    )
  }
}
