import logo from 'assets/images/logos/stanfordgsb.svg'

export const Stanford = {
  logo,
  name: 'Stanford GSB',
  company: 'Stanford',
  projects: [
    {
      key: '7bc5934',
      title: 'Stanford GSB SEC Microsite',
      startDate: 'January 30, 2019',
      progress: {
        percentage: 50,
      },
      open: [
        {
          title: 'Final copy & design feedback',
          done: true
        },
        {
          title: 'Review analytics tracking plan',
          done: false
        }
        /*
        {
          title: 'Update Project Timeline',
          description: 'See "Development Timeline" doc',
          date: '4/24/2019',
          done: true
        },
        {
          title: 'Email Marketing Service',
          description: `ConstantContact or something else?`,
          date: '4/24/2019',
          done: true
        },
        {
          title: 'GDPR requirements',
          description: 'Requirements are met through "Opt-in Form" messaging',
          date: '4/24/2019',
          done: true
        },
        */
      ],
      pinned: [
        {
          title: 'SEC: Weekly Huddle Agenda',
          url: 'https://drive.google.com/open?id=1SZAsqGOX3Qg5RtJgoMyO72ejHdVNjt0-SOasDKJ-WeY',
          type: 'doc'
        },
        {
          title: 'Online Entrepreurship Tracker',
          url: 'https://docs.google.com/spreadsheets/d/1Zvcchg4KHG1K1jlYwGsRzQS2nCFRDKCjQh1xF1trqR4/edit#gid=0',
          type: 'sheet'
        },
        {
          title: 'Development Timeline',
          url: 'https://paper.dropbox.com/doc/Stanford-Embark-Development-Timeline--Abx26zfgdGF14gexSP898OMRAg-V0PYr8GGLnEoYueeKUn6V#',
          type: 'dropbox'
        },
        {
          title: 'Analytics Tracking Plan',
          url: 'https://docs.google.com/spreadsheets/d/1ZambGUXZtcAbjMETqunyD-FY5JBXaTn86VsO7-HkHi0/edit?usp=sharing',
          type: 'sheet'
        },
      ],
      deliverables: [

        {
          title: 'Final Design',
          progress: {
            percentage: 50,
          },
          open: [
            {
              title: 'Final copy & design feedback',
              done: true
            },
            {
              title: 'Finalized product name',
              description: 'Stanford Embark',
              done: true,
              date: '2019/04/17'
            },
            {
              title: 'Picking a design',
              description: 'Design option 1 (full-width hero image)',
              done: true,
              date: '2019/04/17'
            },
          ],
          files: [
            {
              title: 'Embark: Landing page feedback 4.29.19',
              url: 'https://docs.google.com/document/d/15XLiocI6yhCgbJoHGSVKZnQlzdqDNGG1CmL2vzl9lYw/edit?ts=5cca72dc',
              type: 'doc'
            },
            {
              title: 'Stanford SEC Designs',
              url: 'https://scene.zeplin.io/project/5b5fc006a4fc7a57bc8aa46c',
              type: 'zeplin'
            },
            {
              title: 'Invision Prototype',
              url: 'https://salesforce.invisionapp.com/share/WKQYWYE9H5V#/screens',
              type: 'invision'
            },
            {
              title: `Assessment Content`,
              url: 'https://docs.google.com/document/d/1aE35Z-2YPzwS5mcDnbUq-0rC38raScJFZLpZ_ynwvp8/edit',
              type: 'doc'
            },
            {
              title: 'Design Assets from Stanford',
              url: 'https://drive.google.com/open?id=17Of32tKidlFOehvLqKhfuq2u_Lnj49sN&authuser=anna@aplusd.co&usp=drive_fs',
              type: 'folder'
            },
            {
              title: 'GSB Hero Images',
              url: 'https://drive.google.com/open?id=1_VqQGCCFTVUrlrrjkX7-R62HCr6uIpuo',
              type: 'folder'
            },
            {
              title: 'GSB Logos',
              url: 'https://mygsb.stanford.edu/facstaff/marketing/brand-identity/logos',
              type: 'web'
            }
          ]
        },
        {
          title: 'Website Development',
          progress: {
            percentage: 20,
          },
          open: [
            {
              title: 'Integrate lead form',
              done: false
            },
            {
              title: 'Integrate analytics',
              done: false
            },
            {
              title: 'Browser support requirements',
              done: false
            },
            {
              title: 'GDPR requirements',
              done: true
            }
          ],
          files: [
            {
              title: 'Stanford Embark [STAGING]',
              description: 'Place to see engineering progress',
              url: 'http://stanford-embark-staging.herokuapp.com/',
              type: 'web'
            },
            {
              title: 'Analytics Tracking Plan',
              url: 'https://docs.google.com/spreadsheets/d/1ZambGUXZtcAbjMETqunyD-FY5JBXaTn86VsO7-HkHi0/edit?usp=sharing',
              type: 'sheet'
            },
          ]
        },
        {
          title: 'Email Lead Form & Nurture Email Templates',
          progress: {
            percentage: 10,
          },
          open: [
            {
              title: 'Email copy',
              done: false
            },
            {
              title: 'Email templates & opt-out confirmation design',
              done: false
            },
          ],
          files: [
            {
              title: 'Marketing Opt-in Form',
              url: 'https://docs.google.com/document/u/1/d/1a-osL47Y4GqnyeNSCb_FqgsJ4p6L25wlN3YOxYcF7Ik/edit?ouid=109295462819549407525&usp=docs_home&ths=true',
              type: 'doc'
            },
            {
              title: 'Email Copy',
              url: 'https://docs.google.com/document/d/1jMnMFDnOWAXXBd2-hk-pbbuk_1ilK53mvj0jkvLs0AA/edit?usp=sharing_eil&ts=5cc0e178',
              type: 'doc'
            },
          ]
        },
        {
          title: 'Launch Readiness',
          progress: {
            percentage: 50
          },
          open: [
            {
              title: 'Browser testing',
              done: false
            },
            {
              title: 'Final review from stakeholders',
              done: false
            },
            {
              title: 'Domain setup',
              done: true
            },
            {
              title: 'SSL certificate setup',
              done: true
            },
          ]
        },
        {
          title: 'Design V1',
          progress: {
            percentage: 100,
            date: '2019/03/29',
          },
          files: [
            {
              title: 'Invision Prototype',
              url: 'https://salesforce.invisionapp.com/share/WKQYWYE9H5V#/screens',
              type: 'invision'
            }
          ]
        },
        {
          title: 'Wireframe Design & Prototype',
          progress: {
            percentage: 100,
            date: '2019/03/07',
          },
          files: [
            {
              title: 'Invision Prototype',
              url: 'https://salesforce.invisionapp.com/share/WKQYWYE9H5V#/screens',
              type: 'invision'
            }
          ]
        },
        {
          title: 'Product Spec for User Flow',
          progress: {
            percentage: 100,
            date: '2019/03/07',
          },
          files: [
            {
              title: 'SEC End2End Flow',
              date: '2019/03/07',
              url: 'https://drive.google.com/open?id=1a-LLv5_rA4702fAl71suqrH_C_B3hz9t4rsjivp6hVk',
              type: 'presentation'
            },
            {
              title: 'Stanford GSB Marketing Launch Structure',
              date: '2019/02/15',
              url: 'https://docs.google.com/presentation/d/1bX5zo4NsGzo3lTFfGpQXaDTbuXbhMPbH6qUyiQ8XmpU/edit?usp=sharing',
              type: 'doc'
            },
            {
              title: '[Marketing Plan]',
              url: 'https://docs.google.com/document/d/1UNSKwcPKph0HwIGh75RuCRmGWOEVwdBoMlYPgrpR8l0/edit#',
              type: 'doc'
            }
          ]
        },
        {
          title: 'Project Proposal',
          progress: {
            percentage: 100,
            date: '2019/01/31',
          },
          files: [
            {
              title: 'SEC Implementation Options',
              date: '2019/02/14',
              url: 'https://docs.google.com/presentation/d/1gSbm-dR_5hU-YgVl4kZK5uyhU70Sm7nIWK_HW4XMcHg/edit?usp=sharing',
              type: 'presentation'
            },
            {
              title: 'SEC Marketing Launch',
              date: '2019/01/28',
              url: 'https://docs.google.com/presentation/d/1C10Ha-M8-qRBw9794gOVSl1Ib79PLzf_SJQPXu26RHQ/edit#slide=id.g1ebbef93ea_1_51',
              type: 'presentation'
            },
            {
              title: 'Stanford Project Proposal',
              date: '2019/01/31',
              url: 'https://docs.google.com/document/d/195oBBHh-BJRzSQOY4wNhWqfPZ3fOrF6EFc4glYBn3Hk/edit?usp=sharing',
              type: 'doc'
            }
          ]
        },
      ]
    },
  ]


}