/* tslint:disable:jsx-no-lambda */

import * as React from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router'
import { createBrowserHistory } from 'history'
import { ProjectPage } from 'views/layout/Project/Project'
import { ClientPage } from 'views/layout/Client/Client'

import { Stanford, Salesforce, Harbor } from 'content'

/* Routes JSX */

export class AppRoutes extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Switch>
        {Stanford.projects.map(project => (
          <Route key={project.key} path={`/stanford/${project.key}`} 
            render={_ => <ProjectPage client={Stanford} project={project} />} />
        ))}
        <Route key="salesforce" path="/salesforce-a8ff6c/" render={_ => <ClientPage client={Salesforce} />} />
        {Salesforce.projects.map(project => (
          <Route key={project.key} path={`/salesforce/${project.key}`}
            render={_ => <ProjectPage client={Salesforce} project={project} />} />
        ))}
        {Harbor.projects.map(project => (
          <Route key={project.key} path={`/harbor/${project.key}`}
            render={_ => <ProjectPage client={Harbor} project={project} />} />
        ))}
      </Switch>
    )
  }
}

export const history = createBrowserHistory()
