import classnames from 'classnames'
import React from 'react'
import { Box, List } from 'views/components'
import { Client } from 'content/contentTypes'
import summaryStyles from './Summary.module.scss'
import styles from './ClientSummary.module.scss'

interface Props {
  client: Client
}

export class ClientSummary extends React.Component<Props, any> {
  render() {
    const { client } = this.props
    const current = client.projects.filter(project => project && project.progress.percentage < 100)
    const past = client.projects.filter(project => project && project.progress.percentage === 100)
    return (
      <Box className={classnames(summaryStyles.summary, styles.clientSummary)}>
        <header>
          <img src={client.logo} className={summaryStyles.clientLogo} />
        </header>
        {current.length > 0 && <List title="Current Projects" items={current} />}
        {past.length > 0 && <List title="Past Projects" items={past} />}
      </Box>
    )
  }
}
