import logo from 'assets/images/logos/sfdcanalytics.svg'

export const Salesforce = {
  logo,
  to: '/salesforce-a8ff6c/',
  name: 'Salesforce Analytics',
  company: 'Salesforce',
  projects: [
    {
      key: 'aacc9e',
      to: '/salesforce/aacc9e',
      external: false,
      title: 'ROI Calculator - Analytics Platform',
      startDate: 'Q2 2018',
      progress: {
        percentage: 30
      },
      open: [
        {
          title: 'Additional budget for website build',
          done: false
        },
        {
          title: 'Follow-up with Francois on additional metrics',
          description: 'Not enough data for Einstein Discovery adoption',
          done: false
        },
      ],
      deliverables: [
        {
          title: 'Wireframe',
          progress: {
            percentage: 30,
          },
          files: [
            {
              title: 'EA ROI Wiframe',
              url: 'https://drive.google.com/open?id=1pY09-1fvnp2xkSiZY9k4HwT_c3DTNZ6gqo2YftHYLlI',
              type: 'presentation'
            }
          ]
        },
        {
          title: 'Develop Product Spec',
          progress: {
            percentage: 50,
          },
          open: [
            {
              title: 'Follow-up with Francois on additional metrics',
              description: 'Not enough data for Einstein adoption',
              done: false
            }
          ],
          files: [
            {
              title: 'Adoption Rate',
              url: 'https://drive.google.com/open?id=10QQ33Hm9A2a4WSoibVyLMmCPjRyWRK9QxUNmqXRTwx8',
              type: 'sheet',
              date: 'March 25, 2019'
            },
            {
              title: 'Adoption Count',
              url: 'https://docs.google.com/spreadsheets/d/18B8U4xSUJNUC3nK3YlrVawChO6Dm05eluKHykXRlW2Y/edit#gid=570029254',
              type: 'sheet',
              date: 'March 25, 2019'
            },
            {
              title: 'ROI Data',
              url: 'https://drive.google.com/open?id=1sXBwPB6pbmhCT4LcZa6XU8lM7NooQwNMvQrMyD1MPng',
              type: 'sheet',
            },
            {
              title: 'ROI Data - Cohort Data',
              url: 'https://drive.google.com/open?id=14wshCJvVIfLXwaPLa9QMNQZ2q3tSqU7Yys3ILV7SpE8',
              type: 'sheet'
            }
          ]
        }
      ]
    },
    {
      key: '87b845',
      to: '/salesforce/87b845',
      external: false,
      title: 'Analytics Playground - Maintenance',
      startDate: 'January 1, 2019',
      progress: {
        percentage: 50,
      },
      pinned: [
        {
          title: 'Google Analytics',
          url: 'https://analytics.google.com/analytics/web/?authuser=1#/report-home/a123293358w198216309p192900596',
          type: 'web'
        },
      ],
      deliverables: [
        {
          title: 'Analytics Playground SSL Certificate Renewal',
          progress: {
            percentage: 2,
          },
          open: [
            {
              title: 'Anna to look into old emails',
              done: true
            }
          ],
          files: [
            {
              title: '07/2018 GUS Story',
              url: 'https://gus.lightning.force.com/lightning/r/ADM_Work__c/a07B0000005IrNHIA0/view',
              type: 'web'
            },
            {
              title: 'SSL Cert Instructions',
              url: 'https://confluence.internal.salesforce.com/display/public/INFRASEC/Requesting+or+Revoking+a+Small+Number+of+Certificates+Using+GUS',
              type: 'web'
            }
          ]
        },
        {
          title: 'Site Analytics Update',
          progress: {
            percentage: 100,
            date: 'April 10, 2019'
          },
          files: [
            {
              title: 'Google Analytics',
              url: 'https://analytics.google.com/analytics/web/?authuser=1#/report-home/a123293358w198216309p192900596',
              type: 'web'
            },
          ]
        },
      ]
    },
    {
      key: 'c7d13a',
      to: '/salesforce/c7d13a',
      external: false,
      title: 'Data Generator - Q4 2018',
      startDate: 'Q4 2018',
      progress: {
        percentage: 0
      },
      pinned: [
        {
          title: 'Spec',
          url: 'https://salesforce.quip.com/KsQZA5jaSOmr',
          type: 'web'
        },
      ],
    }
  ]
}