import classnames from 'classnames'
import * as React from 'react'
import { ListItem as ListItemType } from 'content/contentTypes'
import styles from './List.module.scss'
import { ListItem } from './ListItem'

interface Props {
  title?: string
  items: ListItemType[]
  className?: string
  analytics?: AnalyticsProps
  showProgress?: boolean
}

export class List extends React.Component<Props, any> {
  render() {
    const { analytics, items, title } = this.props
    return (
      <div className={styles.list}>
        {title && <h4 className={styles.listTitle}>{title}</h4>}
        <ul className={classnames(this.props.className, styles.list)}>
          {items.map((item, i) => (
            <ListItem
              key={i}
              showProgress
              analytics={{
                ...analytics
              }}
              {...item}
            />
          ))}
        </ul>
      </div>
    )
  }
}
