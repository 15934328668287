import classnames from 'classnames'
import * as React from 'react'
import { Box, List } from 'views/components'
import { Deliverable } from 'content/contentTypes'
import styles from './Deliverable.module.scss'

interface Props {
  deliverable: Deliverable
  className?: string
  analytics?: AnalyticsProps
}

export class DeliverableModule extends React.Component<Props, any> {
  render() {
    const { analytics, className, deliverable } = this.props
    return (
      <div className={classnames(className, styles.deliverable)}>
        {deliverable.progress.percentage < 100 && 
          <progress className={classnames('progress is-primary', styles.progress)} value={deliverable.progress.percentage} max="100">{deliverable.progress.percentage}%</progress>
        }
        <header className={styles.header}>
          <h3>{deliverable.title}</h3>
          {deliverable.progress.date && <span className={styles.date}>Completed: {deliverable.progress.date}</span>}
        </header>
        <div className={styles.content}>
          {deliverable.open && <List title="Open Items" items={deliverable.open} analytics={analytics}  />}
          {deliverable.files && <List title="Files" items={deliverable.files} analytics={analytics} />}
        </div>
      </div>
    )
  }
}

export default DeliverableModule
