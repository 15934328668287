import React from 'react'
import { Box, List } from 'views/components'
import { Client, Project } from 'content/contentTypes'
import styles from './Summary.module.scss'

interface Props {
  client: Client
  project?: Project
}

export class Summary extends React.Component<Props, any> {
  render() {
    const { client, project } = this.props
    return (
      <Box className={styles.summary}>
        {project && project.progress && <progress className="progress is-primary" value={project.progress.percentage} max="100">15%</progress>}
        <header>
          <img src={client.logo} className={styles.clientLogo} />
          {project && <dl className={styles.meta}>
            <dt>Project:</dt>
            <dd>{project.title}</dd>
            <dt>Start Date:</dt>
            <dd>{project.startDate}</dd>
          </dl>}
        </header>
        {project && project.open && <List
          title="Agenda Items / Open Items"
          items={project.open}
          analytics={{
            category: 'Product - Open Items',
            label: '',
          }}
        />}
        {project && project.pinned && <List title="Pinned Items" items={project.pinned} />}
      </Box>
    )
  }
}
